import Vue from 'vue'
import Vuex from 'vuex'

import { loadData, loadMenu, getActiveHappyHours } from '../api/api'
import { getAdminActiveHappyHours } from '../api/adminApi'

import user from './modules/user'
import order from './modules/order'
import uberMenu from './modules/uberMenu'
import menuGallery from './modules/menuGallery'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        siteName: process.env.VUE_APP_SITE_NAME,
        pageLoad: false,
        menuLoad: false,
        serverOffline: process.env.VUE_APP_IS_OFFLINE == "true",
        settings: null,
        settings2: null,
        menu: null,
        specialMenus: null,
        deliveries: null,
        freeDeliveryAreas: null,
        soupOfTheDay: null,
        tables: null,
        newQueued: 0,
        uberErrors: 0,
        woltAutoaccepted: [],
        canceledOrders: [],
        mobileMenuOpen: false,
        mobileCategoryOpen: false,
        happyHours: [],
        place: "akari",
    },
    getters: {
        siteName: state => state.siteName,
        pageLoad: state => state.pageLoad,
        menuLoad: state => state.menuLoad,
        serverOffline: state => state.serverOffline,
        settings: state => state.settings,
        settings2: state => state.settings2,
        menu: state => state.menu,
        specialMenus: state => state.specialMenus,
        deliveries: state => state.deliveries,
        freeDeliveryAreas: state => state.freeDeliveryAreas,
        soupOfTheDay: state => state.soupOfTheDay,
        tables: state => state.tables,
        newQueued: state => state.newQueued,
        uberErrors: state => state.uberErrors,
        woltAutoaccepted: state => state.woltAutoaccepted,
        canceledOrders: state => state.canceledOrders,
        mobileMenuOpen: state => state.mobileMenuOpen,
        mobileCategoryOpen: state => state.mobileCategoryOpen,
        happyHours: state => state.happyHours,
        place: state => state.place,
    },
    mutations: {
        pageLoad(state) {
            state.pageLoad = true
        },
        menuLoad(state) {
            state.menuLoad = true
        },
        serverOffline(state, payload) {
            state.serverOffline = payload
        },
        settings(state, payload) {
            state.settings = payload
        },
        settings2(state, payload) {
            state.settings2 = payload
        },
        menu(state, payload) {
            state.menu = payload
        },
        specialMenus(state, payload) {
            state.specialMenus = payload
        },
        deliveries(state, payload) {
            state.deliveries = payload
        },
        freeDeliveryAreas(state, payload) {
            state.freeDeliveryAreas = payload
        },
        soupOfTheDay(state, payload) {
            state.soupOfTheDay = payload
        },
        tables(state, payload) {
            state.tables = payload
        },
        newQueued(state, payload) {
            state.newQueued = payload
        },
        uberErrors(state, payload) {
            state.uberErrors = payload
        },
        woltAutoaccepted(state, payload) {
            state.woltAutoaccepted = payload
        },
        canceledOrders(state, payload) {
            state.canceledOrders = payload
        },
        mobileMenuOpen(state, payload) {
            state.mobileMenuOpen = payload
        },
        mobileCategoryOpen(state, payload) {
            state.mobileCategoryOpen = payload
        },
        getHappyHours(state, payload) {
            state.happyHours = payload
        },
        place(state, payload) {
            state.place = payload
        },
    },
    actions: {
        pageLoad({ commit }) {
            commit('pageLoad')
        },
        serverOnline({ commit }) {
            commit('serverOffline', false)
        },
        serverOffline({ commit }) {
            commit('serverOffline', true)
        },
        settings({ commit }, payload) {
            commit('settings', payload)
        },
        settings2({ commit }, payload) {
            commit('settings2', payload)
        },
        menu({ commit }, payload) {
            commit('menu', payload)
        },
        specialMenus({ commit }, payload) {
            commit('specialMenus', payload)
        },
        deliveries({ commit }, payload) {
            commit('deliveries', payload)
        },
        freeDeliveryAreas({ commit }, payload) {
            commit('freeDeliveryAreas', payload)
        },
        setSoupOfTheDay({ commit }, payload) {
            const soupOfTheDay = Object.keys(payload).length === 0 && payload.constructor === Object ? null : payload
            commit('soupOfTheDay', soupOfTheDay)
        },
        setTables({ commit, getters }, payload) {
            let tables = {}
            const place = localStorage.getItem('place') || 'akari'
            const settings = place === 'akari' ? getters.settings : getters.settings2
            if (settings && settings.tables) {
                for (let i = 1; i <= settings.tables; i++) {
                    let table = payload.find(t => t.table_id == i)
                    tables[i] = table === undefined ? null : table
                }
            }
            commit('tables', tables)
        },
        setNewQueued({ commit }, payload) {
            commit('newQueued', payload)
        },
        setUberErrors({ commit }, payload) {
            commit('uberErrors', payload)
        },
        setWoltAutoaccepted({ commit }, payload) {
            commit('woltAutoaccepted', payload)
        },
        setCanceledOrders({ commit }, payload) {
            commit('canceledOrders', payload)
        },
        toggleMobileMenu({ commit, state }) {
            commit('mobileMenuOpen', !state.mobileMenuOpen)
        },
        hideMobileMenu({ commit }) {
            commit('mobileMenuOpen', false)
        },
        toggleMobileCategory({ commit, state }) {
            commit('mobileCategoryOpen', !state.mobileCategoryOpen)
        },
        hideMobileCategory({ commit }) {
            commit('mobileCategoryOpen', false)
        },
        loadPageData({ dispatch }) {
            return loadData()
                .then(res => {
                    dispatch('deliveries', res.data.data.deliveries)
                    dispatch('freeDeliveryAreas', res.data.data.free_delivery_areas)
                    dispatch('setTables', res.data.data.tables)
                })
        },
        loadMenus({ commit, dispatch }) {
            return loadMenu()
                .then(res => {
                    dispatch('menu', res.data.data.menu)
                    dispatch('specialMenus', res.data.data.special_menus)
                    commit('menuLoad')
                })
        },
        getHappyHours({ commit }, restaurant) {
            return getActiveHappyHours(restaurant)
                .then(res => {
                    commit('getHappyHours', res.data)
                })
        },
        getAdminHappyHours({ commit }, restaurant) {
            return getAdminActiveHappyHours(restaurant)
                .then(res => {
                    commit('getHappyHours', res.data)
                })
        },
        setPlace({ commit }, place = "akari") {
            localStorage.setItem('place', place)
            commit('place', place)
        },
    },
    modules: {
        user,
        order,
        uberMenu,
        menuGallery,
    }
})