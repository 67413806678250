<template>
	<PageClosed v-if='pageClosed' />
	<ServerOffline v-else-if='serverOffline' />
	<div v-else-if='!pageLoad' class="pageLoader">
		<Spinner />
	</div>
	<router-view v-else-if="$route.meta.noLayout" />
	<div v-else id="app" :class="{'is-mobile-menu-open': mobileMenuOpen}">
		<main-nav :menuCardMode="menuCardMode"></main-nav>
		<router-view/>
		<main-footer v-if="!menuCardMode"></main-footer>
		<CartBadge v-if='canShowCartBadge' :price='cartPrice[0]' :pointsPrice='cartPrice[1]' />
		<AlarmPopup v-if='showAlarmPopup' />
		<Modal
			key='pointsInfoModal'
			name='pointsInfoModal'
			:adaptive='false'
			:scrollable='true'
			classes='freeFoodModal'
			height='auto'
		>
			<div class="points-modal">
				<h3>Punkty</h3>
				<p>Zarabiaj punkty w naszej restauracji i wymieniaj na różne dania!</p>
				<p>Za każde wydane 100zł otrzymasz 1 punkt</p>
				<p>Dania do wymiany za punkty są widoczne w menu z zakładce "Za punkty"</p>
				<p v-if="!current_user">Żeby skorzystać z punktów trzeba być zalogowanym!</p>
			</div>
		</Modal>
	</div>
</template>

<script>
import PageClosed from './components/others/PageClosed'
import MainNav from './components/layout/MainNav'
import MainFooter from './components/layout/MainFooter'
import ServerOffline from './components/others/ServerOffline'
import CartBadge from './components/menu/CartBadge'
import AlarmPopup from './components/others/AlarmPopup'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'

export default {
	components: { PageClosed, MainNav, MainFooter, ServerOffline, CartBadge, AlarmPopup },
	data: () => ({
		pageClosed: false
	}),
	computed: {
		...mapGetters({
			pageLoad: 'pageLoad',
			serverOffline: 'serverOffline',
			cartPrice: 'order/cartPrice',
			mobileMenuOpen: 'mobileMenuOpen',
			current_user: 'user/current_user',
		}),
		canShowCartBadge () {
			return (this.cartPrice[0] > 0.0 || this.cartPrice[1] > 0) && this.$route.name != 'Cart' && !this.menuCardMode
		},
		showAlarmPopup () {
			let startDate = moment(new Date(2021, 4, 24, 0, 0, 1))
			let endDate   = moment(new Date(2021, 4, 24, 21, 30, 0))
			let inrange = moment().isBetween(startDate, endDate)
			return inrange
		},
		menuCardMode () {
			return this.$route.meta.menuCardMode || false
		}
	},
	methods: {
		...mapActions({
			loadPageData: 'loadPageData',
			loadMenus: 'loadMenus',
			setPlace: 'setPlace',
		})
	},
	async mounted() {
		try {
			this.loadMenus()
			this.loadPageData()
			this.setPlace(localStorage.getItem('place') || 'akari')
		} catch(err) {
			console.log(err)
		}

	}
}
</script>
